import { FormTrackingPlugin } from '@snowplow/browser-plugin-form-tracking'
import { PerformanceNavigationTimingPlugin } from '@snowplow/browser-plugin-performance-navigation-timing'
import {
  SiteTrackingPlugin,
  trackSiteSearch as spTrackSiteSearch,
} from '@snowplow/browser-plugin-site-tracking'
import * as sp from '@snowplow/browser-tracker'
import once from 'lodash/once'
import type { Participant, Researcher } from '@/api/types'
import { debug } from './helpers/debug'
import { getKlaroConsentAsync } from './klaro/getKlaroConsentAsync'

const snowplowLoggingProxy = new Proxy(sp, {
  get(target, prop: keyof typeof sp, receiver) {
    const desc = Object.getOwnPropertyDescriptor(target, prop)

    if (desc && !desc.configurable && !desc.writable) {
      return Reflect.get(target, prop, receiver)
    }

    const originalMethod = target[prop]

    if (typeof originalMethod === 'function') {
      return function (...args: unknown[]) {
        debug('✔ integration called', { name: 'Snowplow', prop, args })

        return (originalMethod as (...args: unknown[]) => unknown).apply(
          target,
          args
        )
      }
    }

    return Reflect.get(target, prop, receiver)
  },
})

export const initializeTracking = async () => {
  snowplowLoggingProxy.newTracker(
    'co',
    import.meta.env['VUE_APP_SNOWPLOW_COLLECTOR_URL'],
    {
      appId: import.meta.env['VUE_APP_SNOWPLOW_APP_ID'],
      discoverRootDomain: true,
      eventMethod: 'post',
      anonymousTracking: { withServerAnonymisation: true },
      stateStorageStrategy: 'none',
      contexts: {
        webPage: true,
      },
      plugins: [
        SiteTrackingPlugin(),
        PerformanceNavigationTimingPlugin(),
        FormTrackingPlugin(),
      ],
    }
  )

  const trackingEnabled = await getKlaroConsentAsync('snowplow')

  if (trackingEnabled) {
    snowplowLoggingProxy.disableAnonymousTracking({
      stateStorageStrategy: 'cookieAndLocalStorage',
    })
  } else {
    snowplowLoggingProxy.enableAnonymousTracking({
      options: {
        withServerAnonymisation: true,
      },
      stateStorageStrategy: 'none',
    })
  }
}

initializeTracking()

export const createSchema = (name: string, version = '1-0-0') =>
  `iglu:co.prolific/${name}/jsonschema/${version}`

function isResearcher(user: Participant | Researcher): user is Researcher {
  return user.user_type === 'researcher'
}

function isParticipant(user: Participant | Researcher): user is Participant {
  return user.user_type === 'participant'
}

// TODO: take from api
type Workspace = {
  id: string
  experimental_group: number
}

type User = (Participant & { pa_enabled?: boolean }) | Researcher

const createUserContext = (getUser: () => User) => () => {
  const user = getUser()
  const { id, user_type, status, balance, currency_code } = user
  const data = {
    id,
    user_type,
    status,
    balance,
    currency_code,
    role_in_event: 'actor',
    ...(isResearcher(user)
      ? {
          available_balance: user.available_balance,
          last_login: user.last_login,
        }
      : {}),
    ...(isParticipant(user) ? { pa_enabled: user.pa_enabled } : {}),
  }

  return {
    schema: createSchema('user', '2-0-0'),
    data,
  }
}

const createWorkspaceContext = (getWorkspace: () => Workspace) => () => {
  const { id, experimental_group } = getWorkspace()
  return {
    schema: createSchema('workspace', '1-0-2'),
    data: {
      id,
      experimental_group,
    },
  }
}

export const setUserContext = once((getUser: () => User) => {
  // first set user id
  snowplowLoggingProxy.setUserId(getUser().id)
  // now send user context with all events
  snowplowLoggingProxy.addGlobalContexts([createUserContext(getUser)])
})

export const setWorkspaceContext = once((getWorkspace: () => Workspace) => {
  snowplowLoggingProxy.addGlobalContexts([createWorkspaceContext(getWorkspace)])
})

// Is there an official type for this?
type AggregatedEvent = {
  pageViewId: null | string
  minXOffset: number
  maxXOffset: number
  minYOffset: number
  maxYOffset: number
  numEvents: number
}

let aggregatedEvent: AggregatedEvent = {
  pageViewId: null,
  minXOffset: 0,
  maxXOffset: 0,
  minYOffset: 0,
  maxYOffset: 0,
  numEvents: 0,
}

export const trackActivity = () => {
  snowplowLoggingProxy.trackSelfDescribingEvent({
    event: {
      schema: createSchema('track_activity'),
      data: {
        minXOffset: aggregatedEvent.minXOffset,
        maxXOffset: aggregatedEvent.maxXOffset,
        minYOffset: aggregatedEvent.minYOffset,
        maxYOffset: aggregatedEvent.maxYOffset,
        activeSeconds: aggregatedEvent.numEvents * 10,
      },
    },
  })
}

export const enableActivityTrackingCallback = () => {
  snowplowLoggingProxy.enableActivityTrackingCallback({
    minimumVisitLength: 10,
    heartbeatDelay: 10,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    callback(event: any) {
      aggregatedEvent = {
        pageViewId: event.pageViewId,
        minXOffset:
          aggregatedEvent.minXOffset < event.minXOffset
            ? aggregatedEvent.minXOffset
            : event.minXOffset,
        maxXOffset:
          aggregatedEvent.maxXOffset > event.maxXOffset
            ? aggregatedEvent.maxXOffset
            : event.maxXOffset,
        minYOffset:
          aggregatedEvent.minYOffset < event.minYOffset
            ? aggregatedEvent.minYOffset
            : event.minYOffset,
        maxYOffset:
          aggregatedEvent.maxYOffset > event.maxYOffset
            ? aggregatedEvent.maxYOffset
            : event.maxYOffset,
        numEvents: aggregatedEvent.numEvents + 1,
      }
    },
  })
}

export const trackCustomEvent = ({
  name,
  data,
  version = '1-0-0',
}: {
  name: string
  data: Record<string, unknown>
  version: string
}) => {
  snowplowLoggingProxy.trackSelfDescribingEvent({
    event: {
      schema: createSchema(name, version),
      data,
    },
  })
}

export const trackStructEvent = ({
  category,
  action,
  label,
  property,
  value,
  context,
}: {
  category: string
  action: string
  label?: string
  property?: string
  value?: number
  context?: sp.SelfDescribingJson<Record<string, unknown>>[]
}) => {
  snowplowLoggingProxy.trackStructEvent({
    category,
    action,
    label,
    property,
    value,
    context,
  })
}

export const trackSiteSearch = ({
  terms,
  filters,
  totalResults,
  pageResults,
}: {
  terms: string[]
  filters?: Record<string, string | boolean>
  totalResults?: number
  pageResults?: number
}) => {
  spTrackSiteSearch({
    terms,
    filters,
    totalResults,
    pageResults,
  })
}

export const teardown = () => {
  snowplowLoggingProxy.clearGlobalContexts()
}

export const trackOnboardingFlow = (
  action: string,
  { isUrlCampaign = false }
) => {
  const category = isUrlCampaign
    ? 'fasttracking_url_campaign_v1'
    : 'waitlist_invites_v1'

  snowplowLoggingProxy.trackStructEvent({
    category,
    action,
  })
}

export const snowplow = snowplowLoggingProxy

export { enableFormTracking } from '@snowplow/browser-plugin-form-tracking'
